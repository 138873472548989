<template>
  <div class="Servicos">
    <!-- Banner !-->
    <div
      class="flex items-center w-full h-28em md:h-45em bg-layout_secondary bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8"
    >
      <div class="mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12 lg:col-span-7">
            <span class="text-white font-Montserrat text-xl">NOSSOS SERVIÇOS</span>
            <h1
              class="text-white font-Montserrat text-4xl md:text-6xl mt-3"
            >
              Conheça nossos produtos e serviços
            </h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Produtos !-->
    <div id="produtos" class="py-12 md:py-28 mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-12">
                <h2
                    class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
                    >
                    Linha de produtos
                </h2>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-po_de_pedra bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Pó de pedra</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-granilha bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Granilha</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-pedrisco bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Pedrisco</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-pedrisco_com_po bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Pedrisco com pó</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-graduada bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Graduada</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-brita_1_2 bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Brita 1/2</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-brita_1 bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Brita 1</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-brita_2 bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Brita 2</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-brita_3 bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Brita 3</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-pedra_a_4 bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Pedra A-4</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-marroada bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Marroada</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-12 mt-10 mb-10 md:mt-20 md:mb-20">
                <h2
                    class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
                    >
                    Locação de equipamentos
                </h2>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-britador_movel bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Britador primário móvel sobre esteiras</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-britador_primario bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Britador primário</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-rompedor_hidraulico bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Rompedor hidráulico</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-pa_carregadeira bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Pá carregadeira</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-bridador_hidrocone bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Britador hidrocone</span>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 h-28em md:h-30em">
                <div class="relative w-full h-full bg-trator_de_esteira bg-cover">
                    <span class="absolute bottom-5 left-4 font-Montserrat font-semibold text-xl text-white">Trator de esteira</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Interessado !-->
    <div id="interessado" class="py-12 md:py-28 bg-layout_branco bg-cover bg-no-repeat bg-cente">
      <div class="mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 md:col-span-8">
            <h2
                class="text-gray-900 font-Montserrat text-2xl md:text-5xl"
              >
                Interessado nos nossos serviços? Entre em contato com a gente.
            </h2>
            <button
              @click="$modal.show('modal_zap')"
              class="border-none bg-secondary text-base font-semibold text-white py-6 font-Montserrat mt-4 w-full hover:bg-primary md:w-60 mt-10"
            >
              Entrar em contato
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
